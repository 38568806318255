.card {
  height: calc(100vh - 6.25rem);
  @apply flex flex-1 relative opacity-0;
  @apply max-h-[44rem] xs:max-h-[44rem] sm:max-h-[37.5rem] transition-all;
  @apply opacity-0 animate-[show_.9s_ease-in-out_1_forwards];
}

.link {
  @apply h-full w-full flex relative;
  @apply overflow-hidden bg-cover flex bg-center flex-1;
}

.link:hover .title {
  background-position: 0%;
}

.link:hover .title::before {
  @apply w-full;
}

.link:hover .imageBoxHero {
  @apply brightness-75 transition-['brightness'] scale-125;
  @apply duration-700;
}

.link:hover .imageBoxHeroDark {
  @apply brightness-[0.4];
}

.titleContainer {
  @apply h-full w-full flex items-center justify-center flex-col flex-1 z-10;
}

.title {
  @apply uppercase text-xxlg font-bold text-general font-steppe duration-1000 mx-4 w-fit;
  @apply text-center z-10 relative bg-clip-text bg-[length:200%_100%];
  background-position: -100%;
  transition: all 0.5s ease-in-out;
  background-image: linear-gradient(
    to right,
    var(--color-light),
    var(--color-light) 50%,
    var(--color-general) 50%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.title::before {
  @apply absolute block w-0 h-[3px] -bottom-[3px] left-0 z-0 bg-light;
  content: '';
  transition: all 0.5s ease-in-out;
}

.subtitle {
  @apply uppercase text-xs md:text-sm text-general font-bold px-3;
  @apply text-center z-10 relative bg-clip-text bg-[length:200%_100%];
}

.link:hover .subtitle {
  @apply text-light;
  @apply shadowPulseFilter;
}

.textContainer {
  @apply h-full w-full flex items-center justify-center flex-col flex-1 p-[13%];
}

.text {
  @apply text-light font-steppe text-xxlg;
}

.contactButton {
  @apply py-2 text-center mt-10 !important;
}

.contactButtonText {
  @apply text-light text-sm;
}

.imageBox,
.imageBoxHero {
  @apply absolute top-0 bottom-0 left-0 right-0 overflow-hidden flex flex-1 duration-700;
}

.image {
  @apply h-full w-full object-cover overflow-hidden;
}

.shadowPulseFilter {
  @apply animate-[shadowPulse_1.5s_linear_infinite];
  @apply duration-700;
}

.contactButton:hover {
  @apply scale-105 transition-transform;
}
