.loaderContainer {
  @apply h-screen bg-loader-sm-pattern sm:bg-loader-pattern flex bg-cover bg-right-bottom fixed top-0 bottom-0 left-0 right-0 z-50;
}

.loaderContainer svg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 150px;
  width: 150px;
}

.overlay {
  @apply absolute top-0 bottom-0 left-0 right-0 bg-black/60 sm:bg-black/50;
}
