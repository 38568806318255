.container {
  @apply bg-grey rounded-3xl p-5;
}

.titleBox {
  @apply flex justify-between items-end xs:items-center mb-7 lg:mb-9 flex-col xs:flex-row;
}

.title {
  @apply font-bold text-sm text-light xs:shrink-0 self-start mr-4 sm:mr-8;
  @apply subpixel-antialiased;
}

.price {
  @apply text-xs xs:text-sm lg:text-lg text-light text-right leading-none flex-1;
}

.text {
  @apply text-light text-xs sm:text-xxs md:text-xs;
}

.list {
  @apply list-disc list-inside;
}

.shadowPulseFilter {
  @apply animate-[shadowPulse_1.4s_linear_infinite];
}

.priceButton:hover {
  @apply scale-95 transition-transform;
}
