.logo {
  @apply text-white text-lg font-steppe font-bold;
}

.textLarge span {
  @apply text-xs xs:text-sm md:text-lg;
}

.textLarge sub {
  @apply text-xxxs md:text-xxs;
}

.textSmall span {
  @apply text-xxs xs:text-xs md:text-sm;
}

.textSmall sub {
  @apply text-xxxs;
}
