.firstSection {
  @apply bg-general py-6 md:py-8 lg:py-10 flex flex-col justify-center items-center border-y-2 border-dark;
}

.title {
  @apply text-light text-xs md:text-sm font-bold mb-4 md:mb-5;
}

.iconsGroup {
  @apply text-light flex space-x-7 xs:space-x-6 animate-pulse;
}

.secondSection {
  @apply bg-dark flex flex-col px-6 md:px-9 py-5;
}

.list {
  @apply flex flex-col self-end items-end space-y-1 mb-20 lg:mb-24 text-xxs sm:text-xs;
}

.bottomContainer {
  @apply flex justify-between items-center flex-col xs:flex-row;
}

.navList {
  @apply flex space-x-6 order-1 xs:order-none;
}

.logo {
  @apply mt-6 xs:mt-0 order-2 xs:order-none;
}

.text {
  @apply !text-[1.3rem] xs:!text-sm;
}
