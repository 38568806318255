.transition {
  @apply fixed inset-0 bg-dark bg-opacity-50 transition-opacity;
}

.content {
  @apply overflow-y-auto relative h-full px-6;
}

.container {
  @apply fixed inset-0 z-10 my-[8%] md:my-[5%] mx-5 xs:mx-8 lg:mx-12 xl:mx-20;
}

.dialog {
  @apply transform rounded-2xl bg-light pt-8 text-left shadow-xl transition-all w-full absolute inset-0 overflow-hidden mx-auto;
}

.buttonContainer {
  @apply absolute right-0 top-0 mr-6 pt-3 sm:block;
}

.close {
  @apply rounded-md bg-light text-general focus:outline-none focus:ring-offset-0 absolute;
}

.title {
  @apply font-bold leading-6 text-general text-center mb-2.5;
}

.subtitle {
  @apply font-bold text-sm;
}

.list {
  @apply mt-2 text-xxs space-y-4;
}
