@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('/src/fonts/Steppe/Steppe.css');

body {
  margin: 0;
  font-family: 'Steppe', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-general;
}

@layer components {
  .template {
    @apply min-h-screen bg-general flex justify-center;
  }

  .sections-frame {
    @apply px-8 py-7 xl:px-10 xl:py-8;
  }
}

@layer base {
  :root {
    --color-light: #f8f8f8;
    --color-general: #2c2c2c;
  }

  [type='text'],
  [type='checkbox'],
  [type='radio'],
  [type='button'],
  a {
    @apply focus:ring-0 focus:ring-offset-0 focus-visible:outline-none;
  }

  .glow {
    transition: filter 0.4s;
  }

  .glow:hover {
    filter: drop-shadow(0 0 2px #6c6767) drop-shadow(0 0 5px #f8f8f8)
      drop-shadow(0 0 12px #fff);
  }
}

/* Swiper styles: */
.swiper-pagination-bullet {
  @apply hover:cursor-pointer bg-dark;
}

.swiper-button-prev,
.swiper-button-next {
  @apply w-9 h-9 top-[91%] mt-0 absolute bottom-0 z-50 hidden md:flex;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  @apply text-dark text-sm font-bold;
}
