.container {
  @apply bg-general text-white py-4 px-5 xs:px-6 xs:py-5  md:px-9 fixed left-0 right-0 top-0 flex z-50 justify-center;
}

.content {
  @apply w-full max-w-[1600px] flex items-center justify-start bg-general;
}

.contactsBox {
  @apply flex space-x-6 xs:space-x-5 items-center ml-auto;
}

.tel {
  @apply hidden xs:block font-steppe text-xs md:text-sm;
}

.linkBox {
  @apply flex justify-center items-center shrink-0;
}
