.gridContainer {
  @apply grid h-full grid-cols-6 auto-rows-auto gap-y-6 sm:gap-y-8 lg:gap-y-12;
}

.image {
  @apply h-full w-full aspect-auto object-cover object-center;
}

.heroImage {
  @apply max-h-[76vh] sm:max-h-full;
}

.heroContainer {
  @apply grid sm:grid-cols-2 gap-x-10 md:gap-x-12 lg:gap-x-20 px-8 pb-8 sm:px-10 sm:pb-9 md:px-14 md:pb-10 lg:px-20 lg:pb-20 xl:min-h-[80vh] h-full;
  @apply opacity-0 animate-[show_.8s_ease-in-out_1_forwards];
}

.textContainer {
  @apply flex flex-col justify-center items-center mt-6 sm:mt-0;
}

.title {
  @apply font-bold uppercase text-sm lg:text-lg text-light;
}

.text {
  @apply text-light opacity-50 text-xxs md:text-xs lg:text-sm text-center;
}

.description {
  @apply mt-3 text-xxs lg:text-xs;
}
